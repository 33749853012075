export const isLargeBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('large');

export const isSmallBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('small');

export const isMediumBreakpoint = ({ currentBreakpoint }) =>
  currentBreakpoint && currentBreakpoint.includes('medium');

export const getPublishingEnv = (ENVIRONMENT) => {
  if (ENVIRONMENT === 'production') return 'LIVE';

  return 'STAGE';
};

export const readJSONFromScriptTag = ({ id }) => {
  try {
    return JSON.parse(document.querySelector(`#${id}`).textContent);
  } catch (err) {
    return {};
  }
};
