import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import R from 'ramda';
import { HeroFullBlock } from '@cotyorg/ccx-organism-hero';
import Link from '@cotyorg/ccx-atoms/Link';
import Picture from '@cotyorg/ccx-atoms/Picture';
import Heading from '@cotyorg/ccx-atoms/Heading';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import { VideoPlayer } from '@cotyorg/ccx-organism-video';
import { trackVideoPlayEvent } from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/video';
import StandardLayout from '../../templates/StandardLayout';
import { isLargeBreakpoint } from '../../utils';
import PageDescription from '../../organisms/PageDescription';

const trackVideoEvent = ({ url, duration, progress }) =>
  trackVideoPlayEvent({
    url,
    duration,
    progress,
  });

const isContentFirst = ({ index, currentBreakpoint }) =>
  index % 2 !== 0 && isLargeBreakpoint({ currentBreakpoint });

const HomeComponent = ({
  hero,
  topHeading,
  currentBreakpoint,
  sectionHeadings,
  sectionLink,
  homePageVideo,
  homePageImage,
  bottomHeading,
}) => (
  <StandardLayout>
    <div className="content-wrapper">
      <div className="grid-container full">
        <OptionalSection
          renderCondition={!!topHeading}
          section={() => (
            <Heading
              level={1}
              className="home-description-top"
              dangerouslySetInnerHTML={{ __html: topHeading }}
            />
          )}
        />

        <OptionalSection
          renderCondition={!!homePageImage && !homePageVideo}
          section={() => (
            <div className="home-image">
              <Picture
                alt={R.prop('alt', homePageImage)}
                src={R.prop('imageSrc', homePageImage)}
                srcset={R.prop('imageSrcSet', homePageImage)}
              />
            </div>
          )}
        />

        <OptionalSection
          renderCondition={!!homePageVideo}
          section={() => (
            <div className="home-video">
              <VideoPlayer
                playsinline
                autoPlay
                loop
                videosStoreDataKey="homePageVideo"
                playIconSrc="/ccx-files/assets/play.svg"
                pauseIconSrc="/ccx-files/assets/pause.svg"
                muteIconSrc="/ccx-files/assets/mute.svg"
                volumeIconSrc="/ccx-files/assets/volume.svg"
                shrinkVideoIconSrc="/ccx-files/assets/shrink.svg"
                expandVideoIconSrc="/ccx-files/assets/expand.svg"
                muted
                onPlay={trackVideoEvent}
                showOnlyMuteControls
              />
            </div>
          )}
        />

        <PageDescription
          heading={R.path(['introductionSection', 'heading'], sectionHeadings)}
          text={R.path(
            ['introductionSection', 'introduction'],
            sectionHeadings
          )}
          link={R.path(['introductionSection', 'link', 'url'], sectionHeadings)}
          url={R.path(['introductionSection', 'link', 'url'], sectionHeadings)}
          discoverButtonLabel={R.path(
            ['introductionSection', 'link', 'text'],
            sectionHeadings
          )}
        />

        {R.keys(hero).map((key, index) => (
          <div className="home-hero" key={key}>
            <HeroFullBlock
              isContentFirst={isContentFirst({ index, currentBreakpoint })}
              heroStoreDataKey={key}
              headingLevel={2}
            />
          </div>
        ))}

        <div className="grid-container text--center full">
          {R.path(['cta', 'url'], sectionLink) && (
            <p className="bottom-section__link-container">
              <Link
                extraClass="bottom-section__link"
                link={R.path(['cta', 'url'], sectionLink)}
              >
                {R.path(['cta', 'title'], sectionLink)}
              </Link>
            </p>
          )}

          <OptionalSection
            renderCondition={!!bottomHeading}
            section={() => (
              <Heading
                level={2}
                className="home-description-bottom"
                dangerouslySetInnerHTML={{ __html: bottomHeading }}
              />
            )}
          />
        </div>
      </div>
    </div>
  </StandardLayout>
);

HomeComponent.propTypes = {
  hero: PropTypes.objectOf(PropTypes.object),
  topHeading: PropTypes.string,
  currentBreakpoint: PropTypes.string,
  sectionHeadings: PropTypes.objectOf(PropTypes.object),
  sectionLink: PropTypes.objectOf(PropTypes.object),
  homePageVideo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ),
  homePageImage: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ),
  bottomHeading: PropTypes.string,
};

export default connect(({ content, breakpoints }) => ({
  hero: R.path(['hero'], content),
  topHeading: R.prop('topHeading', content),
  currentBreakpoint: R.path(['current'], breakpoints),
  sectionHeadings: R.prop('sectionHeadings', content),
  sectionLink: R.prop('sectionLink', content),
  homePageVideo: R.path(['videos', 'homePageVideo'], content),
  homePageImage: R.path(['images', 'homePageImage'], content),
  bottomHeading: R.prop('bottomHeading', content),
}))(HomeComponent);
